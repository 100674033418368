import React from 'react';
import './App.css';
import Header from './components/header';
import Home from './components/Home';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Work from './components/Work';
import Footer from './components/footer';

export default function App() {
    return (
        <div className="App">
            <Header />
            <Home />
            <Skills />
            <Work />
            <Projects />
            <Footer />
        </div> 
    );
}