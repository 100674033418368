import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import BlackRock from "../images/blackrock.png";
import PNC from "../images/pncLogo.png";
import Clark from "../images/clarkLogo.jpeg";

const experienceData = [
  {
    date: "May 2025",
    company: "BlackRock",
    title: "Summer Analyst - Data Engineer",
    description: [
      "• Incoming summer analyst as a data engineer on Aladdin Data (Summer 2025)",
    ],
    icon: (
      <img
        src={BlackRock}
        alt="BlackRock Icon"
        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
      />
    ),

    iconColor: "#000",
  },
  {
    date: "May 2024 - August 2024",
    company: "PNC Financial Services Group, Inc.",
    title: "Software Engineer Intern",
    description: [
      "• Developed auditing software for testing strategies across 22 Lines of Business, used to grade roughly 25,000 employees.",
      "• Implemented the frontend for a data entry form using React.js, Material UI, and Formik (data validation).",
      "• Performed API calls using Axios to POST & GET data within a SQL database; used Postman to validate endpoints.",
      "• Authored frontend unit tests for React components and data validation with Jest, averaged a code coverage of 93.8%.",
      "• Completed 43 story points within 6 weeks, increasing the team’s sprint average by 37%.",
    ],
    icon: (
      <img
        src={PNC}
        alt="PNC Icon"
        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
      />
    ),

    iconColor: "#000",
  },
  {
    date: "May 2024 - August 2024",
    company: "A. James Clark School of Engineering",
    title: "Assistant to the Associate Director",
    description: [
      "• Developed auditing software for testing strategies across 22 Lines of Business, used to grade roughly 25,000 employees.",
      "• Implemented the frontend for a data entry form using React.js, Material UI, and Formik (data validation).",
      "• Performed API calls using Axios to POST & GET data within a SQL database; used Postman to validate endpoints.",
      "• Authored frontend unit tests for React components and data validation with Jest, averaged a code coverage of 93.8%.",
      "• Completed 43 story points within 6 weeks, increasing the team’s sprint average by 37%.",
    ],
    icon: (
      <img
        src={Clark}
        alt="PNC Icon"
        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
      />
    ),

    iconColor: "#000",
  },
];

const Experience = () => {
  return (
    <section id="work" className="flex flex-col mb-10">
      <div className="mb-10 flex flex-col items-center pt-20">
        <h1 className="mb-5 text-5xl font-bold">Experience</h1>
        <hr class="bg-black w-3/4"></hr>
      </div>
      <div className="w-full h-full flex">
        <VerticalTimeline
          lineColor="#fff"
          className="w-[300px] relative"
          layout="1-column-left"
        >
          {experienceData.map((exp, index) => (
            <VerticalTimelineElement
              key={index}
              contentStyle={{
                background: "#F5F5F5",
                color: "#000",
                marginLeft: "70px",
              }}
              contentArrowStyle={{ borderRight: "7px solid #fff" }}
              icon={exp.icon}
              iconStyle={{ background: exp.iconColor, color: "#000", height:"50px", width: "50px", marginLeft:"-5px"}}
            >
              <div className="text-left">
                <h3 className="text-2xl font-bold text-black">{exp.company}</h3>
                <h4 className="text-xl text-black">{exp.title}</h4>
                <h5 className="text-lg text-black italic">{exp.date}</h5>
                <ul className="ml-6 mt-3 space-y-2">
                  {exp.description.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
      
    </section>
  );
};

export default Experience;
