import "../CSS/Skills.css";

const languages = [
  { classN: "devicon-javascript-plain colored", lang: "JavaScript" },
  { classN: "devicon-postgresql-plain colored", lang: "SQL" },
  { classN: "devicon-python-plain colored", lang: "Python" },
  { classN: "devicon-java-plain colored", lang: "Java" },
  { classN: "devicon-c-plain colored", lang: "C" },
  { classN: "devicon-csharp-plain colored", lang: "C#" },
  { classN: "devicon-ocaml-plain colored", lang: "OCaml" },
  { classN: "devicon-rust-original", lang: "Rust" },
  { classN: "devicon-html5-plain colored", lang: "HTML" },
  { classN: "devicon-css3-plain colored", lang: "CSS" },
  { classN: "devicon-bash-plain", lang: "Bash" },
];

const frameworks = [
  { classN: "devicon-scikitlearn-plain colored", lang: "Scikit-learn" },
  { classN: "devicon-pandas-plain ", lang: "Pandas" },
  { classN: "devicon-react-plain colored", lang: "React" },
  { classN: "devicon-nodejs-plain colored", lang: "Node" },
  { classN: "devicon-jest-plain colored", lang: "Jest" },
  { classN: "devicon-tailwindcss-plain colored", lang: "Tailwind" },
  { classN: "devicon-materialui-plain colored", lang: "Material UI" },
  { classN: "devicon-unity-plain", lang: "Unity" },
  { classN: "devicon-junit-plain colored", lang: "JUnit" },
  { classN: "devicon-express-original", lang: "Express" },
  { classN: "devicon-puppeteer-plain", lang: "Puppeteer" },
];

/* MongoDB, Postgres, Git, Jira, Linux, Unix, Terminal, Bitbucket, Postman, Firebase,  */
const devtools = [
  { classN: "devicon-mongodb-plain colored", lang: "MongoDB" },
  { classN: "devicon-postgresql-plain colored", lang: "PostgreSQL" },
  { classN: "devicon-git-plain colored", lang: "Git" },
  { classN: "devicon-jira-plain colored", lang: "Jira" },
  { classN: "devicon-linux-plain", lang: "Linux" },
  { classN: "devicon-unix-original colored", lang: "Unix" },
  { classN: "devicon-confluence-plain colored", lang: "Confluence" },
  { classN: "devicon-bitbucket-original colored", lang: "Bitbucket" },
  { classN: "devicon-postman-plain colored", lang: "Postman" },
  { classN: "devicon-firebase-plain colored", lang: "Firebase" },
  { classN: "devicon-github-original", lang: "GitHub" },
];

function listI(classN, lang, hidden) {
  let clN = classN + " text-5xl mb-2";
  if (hidden) {
    clN += " desktop-only";
  }
  return (
    <li className="p-6 text-center ico">
      <i className={clN}></i>
      <p class="desktop-only">{lang}</p>
    </li>
  );
}

export default function Skills() {
  return (
    <div id="skills" className="skillsbg flex items-center justify-center">
      <div className="mb-5 flex flex-col items-center pt-20">
        <h1 className="text-5xl font-bold">Skills</h1>
      </div>
      <hr class="w-3/4 mb-5"></hr>
      <h2 className="text-4xl font-bold mb-5 mt-4">Languages</h2>
      <div class="stock-ticker">
        <ul>{languages.map((el) => listI(el.classN, el.lang, false))}</ul>

        <ul aria-hidden="true" class="desktop-only">
          {languages.map((el) => listI(el.classN, el.lang, true))}
        </ul>
      </div>
      <h2 className="text-4xl font-bold mb-5 mt-4">Frameworks</h2>
      <div class="stock-ticker">
        <ul>{frameworks.map((el) => listI(el.classN, el.lang, false))}</ul>

        <ul aria-hidden="true">
          {frameworks.map((el) => listI(el.classN, el.lang, true))}
        </ul>
      </div>
      <h2 className="text-4xl font-bold mb-5 mt-4">Dev Tools</h2>
      <div class="stock-ticker">
        <ul>{devtools.map((el) => listI(el.classN, el.lang, false))}</ul>

        <ul aria-hidden="true" class="desktop-only">
          {devtools.map((el) => listI(el.classN, el.lang, true))}
        </ul>
      </div>
    </div>
  );
}
