import { Link } from "react-scroll";
import Resume from "../images/Resume - Caleb Holland.pdf";
import { Drawer, IconButton, ListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const link = (icon, link) => {
  return (
    <Link
      to={link}
      smooth="true"
      spy="true"
      activeClass="active"
      className="cursor-pointer flex items-center justify-center mx-4"
    >
      {icon}
    </Link>
  );
};

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <div className="header px-5 flex justify-between items-center">
        <h1 className="text-3xl">
          <Link
            to={"home"}
            smooth="true"
            spy="true"
            className="cursor-pointer title font-bold ml-2"
          >
            Caleb Holland
          </Link>
        </h1>

        <div className="flex items-center links desktop-only ">
          {link("Skills", "skills")}
          {link("Experience", "work")}
          {link("Projects", "about")}
          <a
            href={Resume}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer mx-4"
          >
            Resume
          </a>
          <a href="mailto:hollandcw3@gmail.com" className="cursor-pointer mx-4">
            Contact
          </a>
        </div>
        <div class="mobile-only">
          <div class="icon">
            <IconButton
              edge="start"
              color="white"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: "white" }}
            >
              <MenuIcon sx={{ fontSize: "5vh" }} />{" "}
            </IconButton>
          </div>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                backgroundColor: "#130819",
                color: "lightblue",
                textShadow: "0 5px 6px rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <div className="drawer-header mt-3">
              <IconButton>
                <CloseIcon
                  onClick={toggleDrawer(false)}
                  className="close-button"
                  sx={{ color: "lightblue" }}
                />
              </IconButton>
            </div>
            <ul className="drawer-list">
              <ListItem>
                <Link to="skills" smooth="true" onClick={toggleDrawer(false)}>
                  Skills
                </Link>
              </ListItem>
              <ListItem>
                <Link to="work" smooth="true" onClick={toggleDrawer(false)}>
                  Experience
                </Link>
              </ListItem>

              <ListItem>
                <Link to="about" smooth="true" onClick={toggleDrawer(false)}>
                  Projects
                </Link>
              </ListItem>
              <ListItem onClick={toggleDrawer(false)}>
                <a href={Resume}> Resume</a>
              </ListItem>

              <ListItem onClick={toggleDrawer(false)}>
                <a href="mailto:hollandcw3@gmail.com"> Contact</a>
              </ListItem>
            </ul>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Header;
