import "../CSS/footer.css";
import { FaGithub, FaLinkedin, FaStrava } from "react-icons/fa";

export default function Footer() {
  return (
    <footer>
      <div class="footer-content pt-1">
        <a href="/contact" underline="none">
          © 2025 Caleb Holland
        </a>
        <div class="logos">
         
          <a href="https://github.com/holland-cw3">
            <FaGithub size={24} />
          </a>
          <a
            href="https://www.linkedin.com/in/caleb-holland-0a1168211/"
            class="ml-6"
          >
            {" "}
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.strava.com/athletes/69624144" class="ml-6">
            <FaStrava size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
}
